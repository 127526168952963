import React, { Component } from "react"

import "../scss/FilterTag.scss"

class FilterTag extends Component {
  constructor(props) {
    super(props)
    // Don't call this.setState() here!
    this.state = {
      active: this.props.active,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    /*if (this.state.active === false) {
      this.setState({ active: true })
    }
    else {
      this.setState({ active: false })
    }*/

    this.props.toggleTags(this.props.tag)
  }

  render() {
    const toggleClass = this.props.active
      ? "filter_tag filter_tag_active"
      : "filter_tag"

    return (
      <div aria-hidden="true" className={toggleClass} onClick={this.handleClick} onKeyDown={this.handleClick}>
        <div className="vert_align">
          <span style={{ textTransform: "capitalize" }}>{this.props.tag}</span>
          &nbsp;
          <div className="tag_count">{this.props.totalCount}</div>
        </div>
      </div>
    )
  }
}

export default FilterTag
