import React, { useState } from "react"
import FilterTag from "./FilterTag"
import { graphql, useStaticQuery } from "gatsby"

import "../scss/Filters.scss"

const Filters = props => {
  // Static query
  const data = useStaticQuery(graphql`
    query FilterQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  // sort tag array
  const sortedTags = data.allMarkdownRemark.group.sort((a, b) => {
    if (a.fieldValue.toLowerCase() < b.fieldValue.toLowerCase()) {
      return -1
    }
    if (a.fieldValue.toLowerCase() > b.fieldValue.toLowerCase()) {
      return 1
    }
    return 0
  })

  /*const toggleClass = this.props.active
    ? "filter_tag filter_tag_active"
    : "filter_tag"*/

  // State Hook
  const [open, setOpen] = useState(false)

  const toggleFilters = () => {
    if (open === false) {
      setOpen(true)
    } else {
      setOpen(false)
    }
    console.log(open)
  }

  const toggleOpenClass = open
    ? "work_filter work_filter_open"
    : "work_filter work_filter_closed"

  const toggleButtonClass = open
    ? "toggle_filters toggle_filters_open"
    : "toggle_filters toggle_filters_closed"

  return (
    <>
      <button className={toggleButtonClass} onClick={toggleFilters}>
        Filter Projects &darr;
      </button>

      <div className={toggleOpenClass}>
        <div className="filters_title">Project Tags</div>
        <ul>
          {sortedTags.map((tag, index) => {
            if (
              props.tags.indexOf(tag.fieldValue) === -1 &&
              tag.fieldValue !== "featured" &&
              tag.fieldValue !== "archived"
            ) {
              return (
                <li key={index}>
                  <FilterTag
                    tag={tag.fieldValue}
                    toggleTags={props.toggleTags}
                    active={false}
                    totalCount={tag.totalCount}
                  />
                </li>
              )
            } else if (
              tag.fieldValue !== "featured" &&
              tag.fieldValue !== "archived"
            ) {
              return (
                <li key={index}>
                  <FilterTag
                    tag={tag.fieldValue}
                    toggleTags={props.toggleTags}
                    active={true}
                    totalCount={tag.totalCount}
                  />
                </li>
              )
            } else {
              return null
            }
          })}
        </ul>
        <button className="filter_clear_all" onClick={props.clearTags}>
          Clear All
        </button>
      </div>
    </>
  )
}

export default Filters
