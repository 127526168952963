import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Moment from "moment"

import "../scss/archive.scss"
import Filters from "../components/Filters"

const WorkPage = () => {
  // Static query
  const data = useStaticQuery(graphql`
    query ArchiveIndexQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
        edges {
          node {
            id
            frontmatter {
              path
              title
              type
              image {
                childImageSharp {
                  fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              date
              author
              tags
            }
          }
        }
      }
    }
  `)

  // State Hook
  const [tags, setTags] = useState([])
  //console.log(`tags: ${tags}`)
  //console.log(data.allMarkdownRemark.group)

  const toggleTags = newTag => {
    if (tags.indexOf(newTag) === -1) {
      // tag is not in array
      setTags(tags.concat([newTag]))
    } else {
      // tag is in array
      let newArray = tags.filter(function(tag) {
        return tag !== newTag
      })
      setTags(newArray)
    }
  }

  // clear tags function
  const clearTags = () => {
    setTags([])
  }

  return (
    <Layout>
      <SEO title="Work" />
      <h1 className="page_title">Work</h1>
      <Filters tags={tags} toggleTags={toggleTags} clearTags={clearTags} />
      <div className="archive">
        {data.allMarkdownRemark.edges.map(project => {
          // if any of the tag of the project is in the "tags" state array OR if the "tags" array is empty
          if (
            tags.some(r => project.node.frontmatter.tags.indexOf(r) >= 0) ||
            tags.length === 0
          ) {
            return (
              <Link
                className="archive_preview"
                to={project.node.frontmatter.path}
                key={project.node.id}
              >
                <Img
                  fluid={project.node.frontmatter.image.childImageSharp.fluid}
                  className="archive_preview_image"
                />
                <div className="archive_preview_info">
                  <div className="archive_type_and_date">
                    <div className="archive_preview_type">
                      {project.node.frontmatter.type}
                    </div>

                    <div className="archive_preview_date">
                      {project.node.frontmatter.date === "Ongoing" ? (
                        <>Ongoing</>
                      ) : (
                        Moment(project.node.frontmatter.date).format("YYYY")
                      )}
                    </div>
                  </div>

                  <div className="archive_preview_title">
                    {project.node.frontmatter.title}
                  </div>
                </div>
              </Link>
            )
          } else {
            return null
          }
        })}
      </div>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query udu {
//     allMarkdownRemark {
//       group(field: frontmatter___tags) {
//         fieldValue
//         totalCount
//       }
//     }
//   }
// `

export default WorkPage
